import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import styles from "./UniqueSellingPoint.module.scss";

const propTypes = {
	usps: PropTypes.array,
};

const defaultProps = {
	usps: null,
};

function UniqueSellingPoint({ usps }) {
	if (isArray(usps) && !isEmpty(usps)) {
		return (
			<section className={styles.wrapper__main}>
				{usps.map(({ service_usp_item_title: uspTitle, service_usp_item_body: uspBody }) => (
					<div key={uniqid()} className={styles.grid__col}>
						<div className={styles.grid__title}>{uspTitle}</div>
						<div className={styles.grid__body}>{uspBody}</div>
					</div>
				))}
			</section>
		);
	}

	return null;
}

UniqueSellingPoint.propTypes = propTypes;
UniqueSellingPoint.defaultProps = defaultProps;

export default UniqueSellingPoint;
