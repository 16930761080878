import React, { useState } from "react";
import PropTypes from "prop-types";

import styles from "./FAQItem.module.scss";

const propTypes = {
	question: PropTypes.string,
	answer: PropTypes.string,
};

const defaultProps = {
	question: "",
	answer: "",
};

function FAQItem({ answer, question }) {
	const [expanded, setExpanded] = useState(false);

	return (
		<section
			className={styles.item__wrapperMain}
			onClick={() => setExpanded(!expanded)}
			role="presentation"
		>
			<div className={styles.item__wrapperContent}>
				<section className={styles.item__wrapperCopy}>
					<h1 className={styles.item__question}>{question}</h1>
					{expanded && <p className={styles.item__answer}>{answer}</p>}
				</section>

				<section className={styles.item__wrapperIcon}>
					<div
						className={`${styles.item__icoToggle} ${expanded ? styles["is--expanded"] : null}`}
					/>
				</section>
			</div>
		</section>
	);
}

FAQItem.propTypes = propTypes;
FAQItem.defaultProps = defaultProps;

export default FAQItem;
