import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import Masthead from "../components/Service/Masthead/Masthead";
import CTASection from "../components/Service/CTASection/CTASection";
import UniqueSellingPoint from "../components/Service/UniqueSellingPoint/UniqueSellingPoint";
import RelevantWorks from "../components/Service/RelevantWorks/RelevantWorks";
import Qualities from "../components/Service/Qualities/Qualities";
import FAQ from "../components/Service/FAQ/FAQ";
import Clients from "../components/Clients/Clients";
import SEO from "../components/SEO/SEO";

const propTypes = {
	data: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
};

function ServicePage({ data, location }) {
	// destructure
	const {
		service: {
			childMarkdownRemark: {
				frontmatter: {
					services_seo_title: seoTitle,
					services_seo_description: seoDescription,
					service_title: serviceTitle,
					service_description: serviceDescription,
					service_featured_image: serviceFeaturedImage,
					service_header: serviceHeader,
					service_body: serviceBody,
					service_usp_list: uspList,
					service_relevant_work_list: relevantWorkList,
					service_faq_list: serviceFAQList,
				},
			},
		},
		team: {
			childMarkdownRemark: {
				frontmatter: { team_title: teamTitle, team_quality_list: teamQualityList },
			},
		},
		works: { edges: worksData },
	} = data;

	return (
		<>
			<SEO
				title={seoTitle || serviceTitle}
				description={seoDescription || serviceDescription}
				image={serviceFeaturedImage}
				url={location.href}
				article
			/>
			<Masthead title={serviceTitle} description={serviceDescription} />
			<CTASection header={serviceHeader} body={serviceBody} image={serviceFeaturedImage} />
			<UniqueSellingPoint usps={uspList} />
			<RelevantWorks relevantWorks={relevantWorkList} allWorks={worksData} />
			<Qualities title={teamTitle} qualities={teamQualityList} />
			<FAQ title={serviceTitle} list={serviceFAQList} />
			<Clients />
		</>
	);
}

export const query = graphql`
	query($slug: String!) {
		service: file(childMarkdownRemark: { fields: { slug: { eq: $slug } } }) {
			id
			childMarkdownRemark {
				frontmatter {
					services_seo_description
					services_seo_title
					service_title
					service_description
					service_header
					service_body
					service_display
					service_featured_image
					service_relevant_work_list
					service_faq_list {
						service_faq_item_answer
						service_faq_item_question
					}
					service_usp_list {
						service_usp_item_body
						service_usp_item_title
					}
				}
			}
		}

		team: file(name: { eq: "services" }) {
			id
			childMarkdownRemark {
				id
				frontmatter {
					team_title
					team_quality_list {
						team_quality_item_icon
						team_quality_item_title
						team_quality_item_body
					}
				}
			}
		}

		works: allFile(filter: { relativeDirectory: { eq: "work" } }) {
			edges {
				node {
					name
					childMarkdownRemark {
						frontmatter {
							projectTitle
							projectHeroImage
							projectDescription
							projectClientCompany
						}
						fields {
							slug
						}
					}
				}
			}
		}
	}
`;

ServicePage.propTypes = propTypes;
export default ServicePage;
