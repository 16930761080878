import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import { truncateStr } from "../../../utils";

import styles from "./RelevantWorks.module.scss";

const propTypes = {
	relevantWorks: PropTypes.array,
	allWorks: PropTypes.array,
};

const defaultProps = {
	relevantWorks: null,
	allWorks: null,
};

function RelevantWorks({ relevantWorks, allWorks }) {
	if (!isArray(relevantWorks) || isEmpty(relevantWorks)) {
		return null;
	}

	return (
		<section className={styles.wrapper__main}>
			{isArray(relevantWorks) &&
				!isEmpty(relevantWorks) &&
				relevantWorks.map((relevantItem) =>
					allWorks.map((item) => {
						const {
							node: {
								childMarkdownRemark: {
									frontmatter: {
										projectTitle: title,
										projectDescription: description,
										projectHeroImage: heroImage,
										projectClientCompany: clientCompany,
									},
									fields: { slug },
								},
							},
						} = item;

						if (title.toLowerCase() === relevantItem.toLowerCase()) {
							return (
								<Link key={slug} to={slug} className={styles.col__wrapper}>
									{heroImage && <img src={heroImage} className={styles.col__image} alt={title} />}
									<div className={styles.col__copyWrapper}>
										<h2 className={styles.col__title}>{title}</h2>
										<p className={styles.col__description}>{truncateStr(description, 200)}</p>
										{clientCompany && <p className={styles.col__clientCompany}>{clientCompany}</p>}
									</div>
								</Link>
							);
						}

						return null;
					}),
				)}
		</section>
	);
}

RelevantWorks.propTypes = propTypes;
RelevantWorks.defaultProps = defaultProps;

export default RelevantWorks;
