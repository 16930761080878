import React from "react";
import PropTypes from "prop-types";

import { htmlifyMarkdown } from "../../../utils";

import styles from "./Masthead.module.scss";

const propTypes = {
	title: PropTypes.string.isRequired,
	description: PropTypes.string.isRequired,
};

function Masthead({ title, description }) {
	return (
		<section className={styles.masthead__wrapper}>
			<h1 className={styles.masthead__title}>{title}</h1>
			<h2
				className={styles.masthead__description}
				dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(description) }}
			/>
		</section>
	);
}

Masthead.propTypes = propTypes;

export default Masthead;
