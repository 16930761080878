import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import styles from "./FAQ.module.scss";
import FAQItem from "./FAQItem";

const propTypes = {
	title: PropTypes.string,
	list: PropTypes.array,
};

const defaultProps = {
	title: null,
	list: null,
};

function FAQ({ title, list }) {
	if (isArray(list) && !isEmpty(list)) {
		return (
			<section>
				<div className={styles.masthead__wrapperMain}>
					<div className={styles.masthead__wrapperContent}>
						<h2 className={styles.masthead__header}>Commonly Asked Questions</h2>
						<p className={styles.masthead__about}>About {title}</p>
					</div>
				</div>

				{list.map(({ service_faq_item_answer: answer, service_faq_item_question: question }) => (
					<FAQItem key={uniqid()} answer={answer} question={question} />
				))}
			</section>
		);
	}

	return false;
}

FAQ.propTypes = propTypes;
FAQ.defaultProps = defaultProps;

export default FAQ;
