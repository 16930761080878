import React from "react";
import { StaticQuery, graphql } from "gatsby";
import uniqid from "uniqid";

import styles from "./Clients.module.scss";

function Clients() {
	return (
		<StaticQuery
			query={graphql`
				query {
					data: file(name: { eq: "index" }) {
						id
						childMarkdownRemark {
							frontmatter {
								client_list
							}
						}
					}
				}
			`}
			render={({
				data: {
					childMarkdownRemark: {
						frontmatter: { client_list: clientList },
					},
				},
			}) => (
				<section>
					<div className={styles.wrapper__content}>
						<h1 className={styles.sectionTitle}>OUR PRESTIGE CLIENTS</h1>

						<div className={styles.wrapper__grid}>
							{clientList.map((imgPath) => {
								return (
									<div key={uniqid()} className={styles.clientLogo__wrapper}>
										<img src={imgPath} alt="Client logo" className={styles.clientLogo__imgEl} />
									</div>
								);
							})}
						</div>
					</div>
				</section>
			)}
		/>
	);
}

export default Clients;
