import React from "react";
import PropTypes from "prop-types";
import { isArray, isEmpty } from "lodash";
import uniqid from "uniqid";

import styles from "./Qualities.module.scss";

const propTypes = {
	title: PropTypes.string,
	qualities: PropTypes.array,
};

const defaultProps = {
	title: null,
	qualities: null,
};

function Qualities({ title, qualities }) {
	if (isArray(qualities) && !isEmpty(qualities)) {
		return (
			<section className={styles.wrapper__main}>
				<h1 className={styles.title}>{title}</h1>

				<div className={styles.grid__wrapper}>
					{qualities.map(
						({
							team_quality_item_icon: icon,
							team_quality_item_title: title,
							team_quality_item_body: body,
						}) => {
							return (
								<div key={uniqid()} className={styles.grid__itemWrapper}>
									<div className={styles.grid__imgWrapper}>
										<img src={icon} alt={title} className={styles.grid__imgEl} />
									</div>
									<h1 className={styles.grid__title}>{title}</h1>
									<p className={styles.grid__body}>{body}</p>
								</div>
							);
						},
					)}
				</div>
			</section>
		);
	}

	return null;
}

Qualities.propTypes = propTypes;
Qualities.defaultProps = defaultProps;

export default Qualities;
