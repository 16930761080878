import React from "react";
import PropTypes from "prop-types";

import Button from "../../Button/Button";
import { htmlifyMarkdown } from "../../../utils";

import styles from "./CTASection.module.scss";

const propTypes = {
	header: PropTypes.string,
	body: PropTypes.string,
	image: PropTypes.string,
};

const defaultProps = {
	header: "",
	body: "",
	image: null,
};

function CTASection({ header, body, image }) {
	return (
		<section className={styles.wrapper__main}>
			<div className={styles.wrapper__content}>
				<section className={styles.wrapper__containerImage}>
					<div className={styles.heroImage__wrapper}>
						{image && <img src={image} className={styles.heroImage__imgEl} alt={header} />}
					</div>
				</section>

				<section className={styles.wrapper__containerCopy}>
					<h3 className={styles.copy__header}>{header}</h3>
					<h4
						className={styles.copy__body}
						dangerouslySetInnerHTML={{ __html: htmlifyMarkdown(body) }}
					/>
					<Button
						to="/contact"
						className={styles.copy__btnCTA}
						text="CONTACT US"
						colorScheme="black"
					/>
				</section>
			</div>
		</section>
	);
}

CTASection.propTypes = propTypes;
CTASection.defaultProps = defaultProps;

export default CTASection;
